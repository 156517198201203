<template>
  <div
    class="flex flex-col w-full mt-4 text-xs text-promy-gray-750 font-extrabold"
  >
    <div
      class="flex flex-col items"
      v-for="(item, index) in items"
      :key="index"
    >
      <div
        class="flex justify-between px-6 py-3 border-promy-gray-50 item space-x-4"
        :class="
          child_index % 2 === 0 ? 'bg-promy-gray-50' : 'bg-promy-gray-100'
        "
        v-for="(value, key, child_index) in keys"
        :key="child_index"
      >
        <template v-if="key == 'is_selected' && has_checkbox">
          <div class="flex w-full h-5">
            <Checkbox
              :id="`${index}-${item.is_selected}`"
              v-model="item.is_selected"
            />
          </div>
        </template>
        <template v-else>
          <div class="flex w-4/10">{{ value }}</div>
          <div class="flex flex-1 text-left">{{ item[key] }}</div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    keys: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    has_checkbox: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped>
/* .items:first-child .item:first-child {
  @apply rounded-t-box border-b-2;
} */
.item {
  @apply border-b-2;
}

/* .items:last-child .item:last-child {
  @apply rounded-b-box border-b-0;
} */
.item:first-child {
  @apply bg-white;
}
</style>
